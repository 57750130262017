.Align {
  text-align: center;
}

.swal2-confirm.delete-button-class {
  background-color: #ff0000;
  color: #fff;
  margin-right: 150px;
}

.swal2-cancel.cancel-button-class {
  background-color: #808080;
  color: #fff;
}
