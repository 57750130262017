/* .HorizontalAppointment-imageContainer {
  height: 500px;
} */
/* 
.proactive-cell {
  height: 400px;
} */

/* .css-puowsz-MuiTableCell-root {
  height: 400px;
} */

/* .proactive-multipleSessions {
  height: 500px;
} */

/* .proactive-content {
  height: 500px;
} */

/* .css-1sksvga {
  height: 400px;
} */

/* .proactive-text {
  height: 400px;
} */

.css-toas1h {
  height: 250px;
}

.css-1v5sbkz.HorizontalAppointment-content {
  color: #fff;
  padding: 4px;
  padding-top: 5px !important;
  padding-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

/* .HorizontalAppointment-container {
  height: 500px;
} */

/* .HorizontalAppointment-title {
  height: 500px;
} */

/* .HorizontalAppointment-content {
  height: 500px;
} */
/* .proactive-apptContent {
  height: 400px;
} */

/* .Appointment-appointment {
  height: 500px;
} */

/* .Appointment-clickableAppointment {
  height: 500px;
} */

/* .css-ygorma {
  height: 400px;
} */
